

import React from 'react';
import './EmptyBlogList.css';

const EmptyBlogList = () => (
  <div className='emptyList-wrap'>
    No Posts
  </div>
);

export default EmptyBlogList;
