import React from 'react';
import SubHeaderDetails from '../InnerComponent/SubHeaderDetails';


const CreateSubHeaderDetails = (props) => {
  return (
    <SubHeaderDetails  screen={'create'}/>
  );
};

export default CreateSubHeaderDetails;
