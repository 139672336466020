import React from 'react';
import HeaderDetails from '../InnerComponent/HeaderDetails';


const CreateHeaderDetails = (props) => {
  return (
    <HeaderDetails  screen={'create'}/>
  );
};

export default CreateHeaderDetails;
